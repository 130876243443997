@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
@import "~antd/dist/antd.less";
@import "./vars.less";
@import "./spacing.less";
@import "./media-queries.less";
@import "./gradient.less";

@border-radius: 0.5rem;
@app-width: 800px;

.ant-layout {
  background-color: transparent;
}

.ant-layout-footer {
  background: transparent;
  max-width: @app-width;
  width: 100%;
  margin: 0 auto;
  .Mq(@app-width+30, {
    text-align: center;
  });
  .Mq-min(@app-width+30, {
  .contact-dropdown-wp {
      text-align: right;
    }
  });
}
.ant-layout-header {
  height: 100px;
  display: flex;
  justify-content: center;
  margin-bottom: 2px;
}
.logo-wp {
  display: flex;
  width: 160px;
}

.ant-layout-content {
  background: #fff;
  padding: 2rem 1rem;
  width: 100%;
  .Mq-min(@app-width + 30, {margin: 1rem auto 0.5rem; max-width: @app-width; border-radius: @border-radius;});
}
.fancy-btn-wp {
  text-align: center;
  margin-top: 30px;
}

.fancy-btn {
  padding: 7px 40px;
  height: 50px;
  min-width: 200px;
  line-height: 36px;
  .gradient(linear; @dir:297deg; @primary-color,  @accent-color);
  span {
    position: relative;
    z-index: 2;
  }
  border: none;
  border-radius: 8px;
  font-size: 16px;
  transition: box-shadow @transition, color @transition;
  &::after {
    content: "";
    opacity: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 8px;
    .gradient(linear; @dir:297deg; @accent-color,  @primary-color);
    transition: opacity @transition;
  }
  &:hover {
    .gradient(linear; @dir:297deg; @primary-color,  @accent-color);
    box-shadow: 0px 3px 10px rgba(@primary-color, 0.6);
    &::after {
      opacity: 1;
    }
  }
  &:disabled,
  &:disabled:hover {
    color: @text-color;
    background: @btn-disabled-color;
    &::after {
      opacity: 1;
      .disabled-bg;
    }
  }
}

.terms-label {
  cursor: pointer;
  font-size: 15px;
}

.muted,
.ant-btn-link.muted,
button.muted,
a.muted {
  color: @text-color;
}

.no-steps-btns {
  width: 100% !important;
  .ant-input-number-handler-wrap {
    display: none !important;
  }
}

.input-number-wp {
  position: relative;
  .input-number-extra {
    position: absolute;
    top: 0;
    right: 10px;
    line-height: 37px;
    z-index: 0;
    cursor: auto;
  }
}

.flex-nowrap {
  flex-wrap: nowrap;
}
.w-100 {
  width: 100%;
}

.details-list-title {
  font-size: 16px;
  color: @dark-blue;
}
.text-center {
  text-align: center !important;
}

.form-item-space-between {
  justify-content: space-between;
  flex-wrap: nowrap;
  .Mq-min(575px, {.ant-form-item-control{
    text-align: right;
  }});
  .ant-form-item-label {
    flex-grow: 1 !important;
  }
  .ant-col {
    max-width: none;
    flex: none;
  }
}
.ant-result {
  padding: 48px 0;
}

/// .loading

.loading-wp {
  min-height: 200px;
  position: relative;
  &.is-loading {
    .loading-message {
      position: absolute;
      top: calc(50% + 75px);
      font-size: 18px;
      width: 100%;
      text-align: center;
      z-index: 60;
    }
    &::after {
      z-index: 50;
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-image: url(../images/loading.svg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 70px 89px;
    }
  }
}

ol.guid-steps {
  margin-bottom: 32px;
  li {
    margin-bottom: 8px;
  }
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #fff;
}

@blue-base: #0299FB;@green-base: #1EC670;@red-base: #FF2424;@orange-base: #FF9524;@primary-color: #0299FB;@background-color-light: #F8F9FB;@heading-color: #153051;@text-color: #5A6870;@text-color-secondary: #9CA4A9;@text-color-dark: #C0C4D1;@text-color-secondary-dark: #687393;@body-background: #F2F3F8;@menu-bg: transparent;@menu-dark-color: @text-color-dark;@menu-dark-bg: transparent;@menu-item-active-bg: #181D2D;@menu-item-height: 45px;@menu-dark-item-active-bg: #181D2D;@menu-item-font-size: 15px;@menu-icon-margin-right: 10px;@menu-icon-size: 19px;@menu-icon-size-lg: 19px;@layout-sider-background: #242939;@layout-trigger-background: #242939;@layout-header-height: 80px;@layout-header-padding: 0 20px;@layout-header-background: #FFFFFF;@layout-footer-padding: 24px;@table-border-radius-base: 0;@table-header-bg: #F8F9FB;@table-header-color: @text-color;@font-family: Poppins;@border-radius-base: 6px;@border-radius-sm: 4px;@height-base: 38px;@item-hover-bg: #F8F9FB;@link-hover-color: #20C4F4;@input-placeholder-color: rgb(162, 171, 183);@btn-shadow: 0px 2px 4px rgba(9, 50, 84, 0.1);@btn-primary-shadow: 0px 2px 4px rgba(9, 50, 84, 0.1);@btn-padding-horizontal-base: 24px;@btn-disabled-color: #b1b9be;@page-header-back-color: #242939;@page-header-padding: 0px;@page-header-padding-vertical: 16px;@pagination-item-bg: #F2F3F8;@pagination-item-input-bg: #F2F3F8;@pagination-item-disabled-bg-active: #F2F3F8;@pagination-item-disabled-color-active: #C0C4D1;@pagination-item-link-bg: #F2F3F8;@normal-color: #F2F3F8;@border-color-base: #F2F3F8;@border-color-split: #F2F3F8;@avatar-size-base: 40px;@select-background: #F2F3F8;@checkbox-check-color: #dadde2;@btn-text-hover-bg: #F2F3F8;@picker-bg: #F2F3F8;@input-bg: #F2F3F8;